<template>
  <div style="height: 100vh">
    <div v-html="transaction.html"></div>
  </div>
</template>

<script>
import { TransactionService } from '@/services'

export default {
  name: 'Paymago',
  data () {
    return {
      transaction: {},
      loading: false
    }
  },
  mounted () {
    const html = document.getElementsByTagName('html')[0]
    html.classList = []
    this.init()
  },
  methods: {
    init () {
      TransactionService.check(this.$route.params.id)
          .then(({ data }) => {
            this.transaction = data
          })
    }
  },
}
</script>
